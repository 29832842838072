<template>
  <div>
    <div class="search-title background__blue_title">
      <p class="title-secondary fs-20 color__blue_main fw__bold">{{ $t('users_management.page_title.search') }}</p>
    </div>
    <div class="page-main-content is-filter p__bottom--0 p__top--40"
         :class="{'is-active': openSearch, 'cursor-pointer': !openSearch}" @click="toggleSearch(true)">
      <div class="search-button field">
        <div class="control has-icons-right">
          <input class="input input-modal"
                 :placeholder="$t('users_management.buttons.search_user')" readonly>
          <div class="icon is-small is-right icon-search">
            <div>
              <img width="19px" src="../../assets/svgs/SideBar/ic_search_home.svg"/>
            </div>
          </div>
        </div>
      </div>
      <div class="search " @keyup.enter="searchUser()">
        <div class="columns d-flex">
          <div class="column is-6">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{$t('users_management.labels.input_field.employee_id.label') }}
                </span>
              </div>
              <input class="input" type="text"
                     :class="{'is-error': !vuelidate.filterUser.employee_id.maxLength && vuelidate.$dirty}"
                     :placeholder="$t('users_management.labels.input_field.employee_id.placeholder')"
                     v-model="filterUser.employee_id">
              <span class="error pt-1" v-if="!vuelidate.filterUser.employee_id.maxLength && vuelidate.$dirty">
                {{ $t('users_management.messages.validation.employee_id_maxlength') }}
              </span>
            </div>
          </div>

          <div class="column is-6">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                 {{$t('users_management.labels.input_field.user_name.label') }}
                </span>
              </div>
              <input class="input" type="text"
                     :class="{'is-error': !vuelidate.filterUser.name.maxLength && vuelidate.$dirty}"
                     :placeholder="$t('users_management.labels.input_field.user_name.placeholder')"
                     v-model="filterUser.name">
              <span class="error pt-1" v-if="!vuelidate.filterUser.name.maxLength && vuelidate.$dirty">
                {{ $t('users_management.messages.validation.username_maxlength') }}
              </span>
            </div>
          </div>
        </div>
        <div class="columns d-flex">
          <div class="column is-6">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{$t('users_management.labels.input_field.email.label') }}
                </span>
              </div>
              <input class="input" type="text" :placeholder="$t('users_management.labels.input_field.email.placeholder')"
                     v-model="filterUser.email">
            </div>
          </div>

          <div class="column is-6">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.branch.label') }}
                </span>
              </div>
              <multiselect :list-data="branches"
                           :selected="filterUser.branches"
                           :placeholder="$t('users_management.labels.input_field.branch.placeholder')"
                           @change="getFilter($event, 'branches')"></multiselect>
            </div>
          </div>
        </div>
        <div class="columns d-flex">
          <div class="column is-6">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.division.label') }}
                </span>
              </div>
              <multiselect :list-data="divisions"
                           :placeholder="$t('users_management.labels.input_field.division.placeholder')"
                           :selected="filterUser.divisions"
                           @change="getFilter($event, 'divisions')"></multiselect>
            </div>
          </div>

          <div class="column is-6">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.office.label') }}
                </span>
              </div>
              <multiselect :list-data="offices"
                           :placeholder="$t('users_management.labels.input_field.office.placeholder')"
                           :selected="filterUser.offices"
                           @change="getFilter($event, 'offices')"></multiselect>
            </div>
          </div>
        </div>
        <div class="columns d-flex p__bottom--20">
          <div class="column is-6 ">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.role.label') }}
                </span>
              </div>
              <multiselect :is-up="true" :list-data="roles"
                           :placeholder="$t('users_management.labels.input_field.role.placeholder')"
                           :selected="filterUser.roles"
                           @change="getFilter($event, 'roles')"></multiselect>
            </div>
          </div>

          <div class="column is-6">
            <div class="align-items-center">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.position.label') }}
                </span>
              </div>
              <multiselect :is-up="true" :list-data="positions"
                           :placeholder="$t('users_management.labels.input_field.position.placeholder')"
                           :selected="filterUser.positions"
                           @change="getFilter($event, 'positions')"></multiselect>
            </div>
          </div>
        </div>
        <hr/>
        <div class="modal-card-foot is-justify-content-center background__white footer-user">
          <button @click="searchUser()"
                  class="btn is-success close-modal btn-save c-pointer fs-16">
            {{ $t('users_management.buttons.search_user') }}
          </button>
        </div>
      </div>
      <div class="columns modal-foot-toggle m--0">
        <div @click.stop="toggleSearch()"
             class="column is-full column-toggle-search d-flex is-justify-content-center align-items-center p--10">
          <!--          <i class="fas fa-chevron-down"></i>-->
          <span class="icon-close-search">
            <i class="fas fa-angle-up color__blue_main"></i>
          </span>
        </div>
      </div>
    </div>
    <template v-if="isFiltered">
      <div class="page-main-content m__top--20 page-list-data">
        <div class="columns m__top--0">
          <div class="column p__bottom--0 p__top--0 is-half has-text-left align-self-center d-flex">
            <button @click="createUser()" class="button mr-3 color__white background__blue_main btn-create fs-12">
              <img width="18px" src="@/assets/svgs/ic_add3.svg" class="m__right--7">
                {{ $t('users_management.buttons.create_user') }}
            </button>
            <button class="button mr-3 btn-delete-user fs-12" v-if="hasChecked" @click="deleteAllUser">
              <img width="18px" src="@/assets/svgs/DocDetail/ic_delete2.svg" class="m__right--7">
              {{ $t("buttons.delete") }}
            </button>
          </div>
        </div>
        <div class="columns m__top--20 d-flex">
          <div class="column p__bottom--0 p__top--0 is-align-self-flex-end is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touc">
            <span class="vertical-baseline-middle color__gray_text_1 fs-12">検索結果：全{{ paginate.total || 0 }}件</span>
          </div>
          <div class="column p__bottom--0 p__top--0 has-text-right is-half-mobile is-half-desktop is-half-fullhd
          is-half-tablet is-half-widescreen is-half-touch
          d-flex align-items-center is-justify-content-flex-end">
            <span class="m__right--5 vertical-baseline-middle fs-12 color__gray_text_1">{{ $t('page.page-range') }}</span>
            <div class="dropdown is-right"
                 id="dropdown-pagination"
                 v-click-out="closeDropPageRange">
              <div class="dropdown-trigger align-items-center d-flex">
                <button @click="toggleDropPageRange()"
                        aria-haspopup="true"
                        class="button btn-change-page-range p__top--0 p__bottom--0 vertical-baseline-middle">
                  <span class="w--65 has-text-right p__right--5 fs-12 color__gray_text_1">{{ paginate.perPage }} 件</span>
                  <span class="icon is-small">
                     <img width="8px" src="../../assets/svgs/icon_drop.svg"/>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a :key="'page-range-' + i"
                     @click="changePageRange(e)"
                     class="dropdown-item p__right--10 fs-12 color__gray_text_1"
                     v-for="(e, i) in pageRanges">
                    {{ e }} 件
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column w--100 p__top--0">
            <div class="table-container data-table">
              <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                <tr>
                  <th class="input-title text-center p--12 col-checkbox">
                    <label class="checkbox d-flex is-justify-content-center">
                      <input @change="toggleCheckAll()" type="checkbox" class="is-radiusless" v-model="checkAll">
                    </label>
                  </th>
                  <th class="input-title p--12 input-text">{{ $t('users_management.labels.change.user_name') }}</th>
                  <th class="input-title p--12 input-text">{{ $t('users_management.labels.change.email') }}</th>
                  <th class="input-title p--12">{{ $t('users_management.labels.change.position.label') }}</th>
                  <th class="input-title p--12">{{ $t('users_management.labels.change.role.label') }}</th>
                  <th class="input-title p--12">{{ $t('users_management.labels.change.create_at') }}</th>
                  <th class="input-title p--12 col-action">{{
                      $t('users_management.labels.change.action')
                    }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr :class="{'is-auth': userInfo.id === e.id}"
                    :key="'user' + i"
                    v-for="(e, i) in listUsers">
                  <td class="has-text-centered">
                    <label class="checkbox">
                      <input @change="checkItem(e)" type="checkbox" class="is-radiusless" v-model="e.checked"
                             :disabled="userInfo.id === e.id">
                    </label>
                  </td>
                  <td class="name">{{ e.name }}</td>
                  <td class="email">{{ e.email }}</td>
                  <td class="position">{{ e.position.name }}</td>
                  <td class="role-name">{{ e.role.name }}</td>
                  <td class="created-at">{{ momentFormat(e.created_at) }}</td>
                  <td class="has-text-centered">
                    <div class="d-flex">
                      <button :data-tooltip="(userInfo.id !== e.id && displayTooltip) && $t('tooltip.role')"
                              @click="actionChangeUser('role', e, false, false)"
                              :disabled="userInfo.id === e.id"
                              :class="{'is-auth': userInfo.id === e.id}"
                              class="blob has-tooltip-top button-action-detail">
                        <img width="25px" src="@/assets/svgs/ic_user setting.svg" class="m__right--10" />
                      </button>
                      <button :data-tooltip="(userInfo.id !== e.id && displayTooltip) && $t('tooltip.status')"
                              @click="actionChangeUser('status', e, false, true)"
                              :disabled="userInfo.id === e.id"
                              :class="{'is-auth': userInfo.id === e.id}"
                              class="blob has-tooltip-top button-action-detail">
                        <img width="25px" src="@/assets/svgs/ic_user Log.svg" class="m__right--10" />
                      </button>
                      <button :data-tooltip=" $t('tooltip.edit')" @click="updateUser(e.id)"
                              class="blob has-tooltip-top button-action-detail">
                        <img width="25px" src="@/assets/svgs/ic_edit_1.svg" class="m__right--10" />
                      </button>
                      <button :data-tooltip="(userInfo.id !== e.id && displayTooltip) && $t('tooltip.delete')"
                              @click="deleteUser(e.id)"
                              :disabled="userInfo.id === e.id"
                              :class="{'is-auth': userInfo.id === e.id}"
                              class="blob has-tooltip-top button-action-detail">
                        <img width="25px" src="@/assets/svgs/ic_delete.svg" class="m__right--10" />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="!listUsers || !listUsers.length">
                  <td class="has-text-centered" colspan="7">{{ $t('target_not_found') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-full has-text-centered ">
            <pagination
                :current="paginate.currentPage"
                :per-page="paginate.perPage"
                :total="paginate.total"
                @change="changePage($event)">
            </pagination>
          </div>
        </div>
        <div class="modal" id="modal-change-user">
          <div class="modal-background"></div>
          <div class="modal-card" v-click-out="() =>{closeModal('modal-change-user'); revertStatus()} ">
            <div class="modal-card-head background__gray_dark">
              <p class="modal-card-title input-title color__blue_main text-center fw__bold fs-20">{{ titleChange }}</p>
              <button class="delete" aria-label="close" @click="closeModal('modal-change-user')"></button>
            </div>
            <template @keyup.enter="submitChange()" v-if="!isChangePassword && !isFilter && !isChangeStatus">
              <section class="modal-card-body box-change-role">
                <div class="columns modal-field-content d-flex">
                  <div class="column is-3-mobile is-3-tablet">
                    <label
                        class="input-title color__blue_main fw__bold fs-14">{{
                        $t('users_management.labels.input_field.employee_id.label')
                      }}</label>
                  </div>
                  <div class="column control color__blue_main is-9-mobile is-9-tablet">
                    <p>{{ userChange.employee_id }}</p>
                  </div>
                </div>
                <div class="columns modal-field-content d-flex">
                  <div class="column is-3-mobile is-3-tablet">
                    <label class="input-title color__blue_main fw__bold fs-14">{{ $t('users_management.labels.change.user_name') }}</label>
                  </div>
                  <div class="column control color__blue_main is-9-mobile is-9-tablet">
                    <p>{{ userChange.name }}</p>
                  </div>
                </div>
                <div class="columns modal-field-content d-flex">
                  <div class="column is-one-fourth is-3-mobile is-3-tablet">
                    <label class="input-title color__blue_main fw__bold fs-14">{{ labelChange }}</label>
                  </div>
                  <div class="column is-three-quarters control is-9-mobile is-9-tablet">
                    <div class="dropdown w--100"
                         id="dropdown-change"
                         v-click-out="()=>closeDropdown('dropdown-change')">
                      <div class="dropdown-trigger w--100">
                        <button @click="toggleDropdown('dropdown-change'); scrollBottom()"
                                aria-haspopup="true"
                                class="button w--100 is-justify-content-flex-start">
                          <span class="input-title fs-14 color__blue_button">{{ dropName[keyChange] || placeholderChange }}</span>
                          <span class="icon is-small">
                        <img width="15px" src="@/assets/svgs/ic_drop_2.svg"/>
                      </span>
                        </button>
                      </div>
                      <div class="dropdown-menu w--100" role="menu">
                        <div class="dropdown-content">
                          <a :class="{'is-active': e.id === userChange[keyChange]}"
                             :key="'role' + i"
                             @click="getUserChange(keyChange, e, dropChange)"
                             class="dropdown-item"
                             v-for="(e, i) in dropChange">
                            {{ e.name }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="modal-card-foot is-justify-content-center background__white">
                <button @click="submitChange()"
                        class="button close-modal btn-save m__right--10">
                  {{ $t('buttons.submit') }}
                </button>
                <button @click="closeModal('modal-change-user')"
                        class="button btn-back background__grey close-modal m__left--10">{{
                    $t('buttons.cancel')
                  }}
                </button>
              </div>
            </template>

            <template @keyup.enter="submitChange" v-if="!isChangePassword && !isFilter && isChangeStatus">
              <section class="modal-card-body">
                <div class="columns modal-field-content content-modal-change-status">
                  <label class="label input-title" style="color: black">{{ $t('users_management.popup.change_status.content') }}</label>
                </div>
                <div class="columns pt-4 d-flex has-text-centered mt-1 is-justify-content-center">
                  <div class="column is-3 p-0">
                    <label class="radio pr-4">
                      <input type="radio" name="status" :value="1"
                             v-model="userChange.status" class="radio-status-user">
                      <span>{{ $t('users_management.popup.change_status.active') }}</span>
                    </label>
                  </div>
                  <div class="column is-3 p-0">
                    <label class="radio">
                      <input type="radio" name="status" :value="0"
                             v-model="userChange.status" class="radio-status-user">
                      <span>{{ $t('users_management.popup.change_status.deactivate') }}</span>
                    </label>
                  </div>
                </div>
              </section>
              <div class="modal-card-foot is-justify-content-center background__white">
                <button class="button background__pink close-modal btn-save"
                        @click="submitChangeStatus()">{{ $t('users_management.popup.change_status.choose') }}
                </button>
                <button class="button close-modal btn-back background__grey color__white"
                        @click="closeModal('modal-change-user'); revertStatus()">
                  {{ $t('users_management.popup.delete_user.cancel') }}
                </button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {UserService} from '@/services';
import {ACTION_SET_ACTIVE_SIDEBAR, ACTION_SET_PAGE_TITLE, ACTION_SET_SEARCH_PARAMS} from "@/stores/common/actions";
// import DoughnutMenu from "./components/doughnut-menu";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
// import FloatedMenu from "@/views/users/components/floated-menu";
import User from '@/mixins/user'
import {mapGetters} from 'vuex'
import {momentFormat} from "@/filters"
import findIndex from 'lodash/findIndex'
// import isArray from 'lodash/isArray'

export default {
  name: "UserList",
  // components: {FloatedMenu},
  mixins: [User],
  data() {
    return {
      paginate: {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null
      },
      pageRanges: [10, 20, 50, 100],
      listUsers: [],
      userChange: {},
      dropName: {},
      checkAll: false,
      hasChecked: false,
      isOpenedDoughnut: false,
      menuDoughnut: [
        {
          action: (user) => {
            this.deleteUser(user.id)
          }, icon: 'trash', type: 'danger', title: 'Delete user'
        },
        {
          action: (user) => {
            this.updateUser(user.id)
          }, icon: 'edit', type: 'success', title: 'Edit user'
        },
        {
          action: (user) => {
            this.actionChangeUser('position', user)
          }, icon: 'user-secret', type: 'pink', title: 'Change position'
        },
        {
          action: (user) => {
            this.actionChangeUser('role', user)
          }, icon: 'user-cog', type: 'pink', title: 'Change role'
        },
        {
          action: (user) => {
            this.actionChangeUser('password', user, true)
          }, icon: 'user-lock', type: 'pink', title: 'Change password'
        },
        {
          action: (user) => {
            this.actionChangeUser('office', user)
          }, icon: 'users', type: 'pink', title: 'Change office'
        },
      ],
      dropChange: [],
      dropPositions: [],
      keyChange: '',
      placeholderChange: '',
      apiChange: '',
      apiChangePositionRole: '',
      titleChange: '',
      labelChange: '',
      isChangePassword: false,
      isChangeStatus: false,
      isFilter: false,
      isFiltered: false,
      passwordType: 'text',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      // oldPasswordIncorrect: false,
      passwordNotMatch: false,
      filterUser: {
        employee_id: '',
        name: '',
        email: '',
        branches: [],
        divisions: [],
        offices: [],
        positions: [],
        roles: [],
      },
      openSearch: true,
      pushItem: [],
      submitAvailable: true,
      oldStatus: 1,
      displayTooltip: true,
      windowSize: window.innerWidth
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'authUser',
    }),
  },
  validations: {
    // oldPassword: {
    //   required,
    //   minLength: minLength(8),
    //   maxLength: maxLength(255),
    // },
    newPassword: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(255),
    },
    confirmPassword: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(255),
    },
    filterUser: {
      employee_id: {
        maxLength: maxLength(20)
      },
      name: {
        maxLength: maxLength(50)
      }
    }
  },
  watch: {
    newPassword(val) {
      this.passwordNotMatch = val !== this.confirmPassword
    },
    confirmPassword(val) {
      this.passwordNotMatch = val !== this.newPassword
    },
    windowSize() {
      let page_content_main = this.$el.querySelector('.page-main-content')
      let search_button = this.$el.querySelector('.search-button')
      let padding = 120
      if (this.isTablet) {
        padding = 40
      }
      search_button.style.width = (page_content_main.clientWidth - padding + "px")
    }
  },
  methods: {
    revertStatus() {
      this.userChange.status = this.oldStatus
    },
    toggleDoughnut(val, user) {
      user.doughnut = val
      this.isOpenedDoughnut = val
    },
    momentFormat(date, format = 'YYYY-MM-DD') {
      return momentFormat(date, format)
    },
    actionChangeUser(type, user, password = false, status = false) {
      this.titleChange = ''
      this.labelChange = ''
      this.dropChange = []
      this.dropPositions = []
      this.keyChange = ''
      this.placeholderChange = ''
      this.apiChange = ''
      this.apiChangePositionRole = ''
      this.userChange = user
      this.displayTooltip = false
      this.oldStatus = user.status
      this.userChange.role_id = this.userChange.role && this.userChange.role.id
      this.userChange.position_id = this.userChange.position && this.userChange.position.id
      this.userChange.office_id = this.userChange.office && this.userChange.office.id
      this.$set(this.dropName, 'role_id', this.dropDisplay(this.roles, this.userChange.role_id))
      this.$set(this.dropName, 'position_id', this.dropDisplay(this.positions, this.userChange.position_id))
      this.$set(this.dropName, 'office_id', this.dropDisplay(this.offices, this.userChange.office_id))
      this.isChangePassword = password
      this.isChangeStatus = status
      this.isFilter = false
      this.toggleModal('modal-change-user')
      setTimeout(() => {
        this.modalOpened = true
      })
      switch (type) {
        case 'role': {
          this.titleChange = this.$t('users_management.labels.change.role.title')
          this.labelChange = this.$t('users_management.labels.change.role.label')
          this.dropChange = this.roles
          this.dropPositions = this.positions
          this.keyChange = 'role_id'
          this.placeholderChange = this.rolePlaceholder
          this.apiChange = 'changeRole'
          this.apiChangePositionRole = 'changePosition'

          break
        }
        case 'position': {
          this.titleChange = this.$t('users_management.labels.change.position.title')
          this.labelChange = this.$t('users_management.labels.change.position.label')
          this.dropChange = this.positions
          this.dropPositions = []
          this.keyChange = 'position_id'
          this.placeholderChange = this.positionPlaceholder
          this.apiChange = 'changePosition'

          break
        }
        case 'office': {
          this.titleChange = this.$t('users_management.labels.change.office.title')
          this.labelChange = this.$t('users_management.labels.change.office.label')
          this.dropChange = this.offices
          this.dropPositions = []
          this.keyChange = 'office_id'
          this.placeholderChange = this.officePlaceholder
          this.apiChange = 'changeOffice'

          break
        }
        case 'password': {
          this.titleChange = this.$t('users_management.labels.change.password.title')
          this.labelChange = ''
          this.dropChange = []
          this.dropPositions = []
          this.keyChange = ''
          this.apiChange = 'changePassword'
          break
        }
        case 'status': {
          this.titleChange = this.$t('users_management.popup.change_status.title')
          this.labelChange = this.$t('users_management.labels.change.status')
          this.dropChange = ''
          this.dropPositions = []
          this.keyChange = 'status'
          this.apiChange = 'changeStatus'
          break
        }
        default: {
          break
        }
      }
    },
    openFilter() {
      this.isChangePassword = false
      this.isFilter = true
      this.titleChange = this.$t('users_management.labels.change.filter.title')
      this.toggleModal('modal-change-user')
      setTimeout(() => {
        this.modalOpened = true
      })
    },
    getFilter(val, type) {
      this.filterUser[type] = val.map((e) => {
        return e.id
      })
    },
    searchUser() {
      this.vuelidate.$touch();
      if (this.vuelidate.filterUser.name.maxLength && this.vuelidate.filterUser.employee_id.maxLength) {
        this.isFiltered = true
        this.getUsers(this.paginate.currentPage, this.paginate.perPage)
        this.closeModal('modal-change-user')
      }
    },
    clearFilter() {
      this.filterUser = {
        name: '',
        offices: [],
        positions: [],
      }
      // this.getUsers(this.paginate.currentPage, this.paginate.perPage)
      // this.closeModal('modal-change-user')
    },
    togglePassWord(password) {
      const type = this.$refs[password].getAttribute('type')
      if (type === 'password') {
        this.$refs[password].setAttribute('type', 'text')
      } else {
        this.$refs[password].setAttribute('type', 'password')
      }
    },
    createUser() {
      this.$router.push({name: 'Users.Create'}, () => {
      })
    },
    updateUser(id) {
      this.$router.push({name: 'Users.Update', params: {id}}, () => {
      })
    },
    deleteUser(id) {
      this.displayTooltip = false
      this.$popup(
          this.$t('users_management.popup.delete_user.title'),
          this.$t('users_management.popup.delete_user.content'),
          {
            okText: this.$t('users_management.popup.delete_user.ok'),
            cancelText: this.$t('users_management.popup.delete_user.cancel'),
            iconClass: 'info-circle',
            type: 'warning',
            checkScreenUser: true,
          },
          {
            onOk: async (d) => {
              await UserService.delete(id)
                  .then(() => {
                    this.$toast.success(this.$t('users_management.messages.delete_success'))
                    this.getUsers(this.paginate.currentPage, this.paginate.perPage)
                    this.hasChecked = false
                    this.checkAll = false
                  })
                  .catch((err) => {
                    console.log(err)
                    this.$toast.error(this.$t('users_management.messages.delete_failed'))
                  })
              d.closeDialog()
              this.displayTooltip = true
            },
            onCancel: (d) => {
              d.closeDialog()
              this.displayTooltip = true
            },
            onClickOut: (d) => {
              d.closeDialog()
              this.displayTooltip = true
            }
          }
      )
    },
    scrollBottom() {
      let box = this.$el.querySelector('.box-change-role')
      if (box) {
        setTimeout(() => {
          box.scrollTop = box.scrollHeight
        })
      }
    },
    getUserChange(key, value, list, drop = 'dropdown-change') {
      this.userChange[key] = value.id
      this.$set(this.dropName, key, this.dropDisplay(list, this.userChange[key]))
      this.closeDropdown(drop)
    },
    async submitChange() {
      if (this.submitAvailable) {
        let data = {}
        data['user_id'] = this.userChange.id
        data[this.keyChange] = this.userChange[this.keyChange]
        if (this.dropPositions && this.dropPositions.length) {
          data.position_id = this.userChange.position_id
        }
        this.submitAvailable = false
        await UserService[this.apiChange](data)
            .then((res) => {
              if (res) {
                this.submitAvailable = true
                this.$toast.success(this.$t('users_management.messages.change_role_success'))
                this.getUsers(this.paginate.currentPage, this.paginate.perPage)
              }
              if (this.apiChangePositionRole) {
                UserService[this.apiChangePositionRole](data)
                    .then((res) => {
                      if (res) {
                        this.getUsers(this.paginate.currentPage, this.paginate.perPage)
                      }
                      this.closeModal('modal-change-user')
                    })
                    .catch(() => {
                      this.closeModal('modal-change-user')
                    })
              }
              this.closeModal('modal-change-user')
            })
            .catch((err) => {
              this.submitAvailable = true
              this.$toast.error(err.data.message)
              this.closeModal('modal-change-user')
            })
      }
    },
    async submitChangeStatus() {
      if (this.submitAvailable) {
        this.submitAvailable = false
        this.oldStatus = this.userChange.status
        await UserService.changeStatus(this.userChange.id, this.userChange.status)
            .then((res) => {
              this.submitAvailable = true
              if (res) {
                this.$toast.success(this.$t('users_management.messages.change_status_success'))
              }
              this.closeModal('modal-change-user')
            })
            .catch((err) => {
              this.submitAvailable = true
              this.$toast.error(err.data.message)
              this.closeModal('modal-change-user')
            })
      }
    },
    async submitChangePassword() {
      this.vuelidate.$touch()
      this.passwordNotMatch = this.newPassword !== this.confirmPassword
      if (!this.vuelidate.$invalid && !this.passwordNotMatch && this.submitAvailable) {
        let data = {
          user_id: this.userChange.id,
          password: this.newPassword,
          password_confirmation: this.confirmPassword,
        }
        this.submitAvailable = false
        await UserService[this.apiChange](data)
            .then(() => {
              this.submitAvailable = true
              this.$toast.success(this.$t('users_management.messages.update_success'))
              this.getUsers(this.paginate.currentPage, this.paginate.perPage)
              this.oldPassword = this.newPassword = this.confirmPassword = ''
              this.$nextTick(() => {
                this.vuelidate.$reset()
              });
              this.closeModal('modal-change-user')
            })
            .catch((err) => {
              this.submitAvailable = true
              console.log(err)
              this.$toast.error(this.$t('users_management.messages.update_failed'))
              this.closeModal('modal-change-user')
            })
      }
    },
    closeDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.remove('is-active')
    },
    toggleDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.toggle('is-active')
    },
    changePageRange(range) {
      this.paginate.perPage = range
      this.hasChecked = false;
      this.checkAll = false;
      this.closeDropPageRange()
      this.getUsers(1, this.paginate.perPage)
    },
    async getUsers(page, limit) {
      let data = {}
      Object.keys(this.filterUser).map((e) => {
        data[e] = this.filterUser[e]
      })
      data.page = page
      data.limit = limit
      this.$store.dispatch(ACTION_SET_SEARCH_PARAMS, {key: 'users', data})
      localStorage.setItem('searchParams', JSON.stringify(data))
      const employee_id = this.filterUser.employee_id
      const name = this.filterUser.name
      const email = this.filterUser.email
      const branches = this.filterUser.branches
      const divisions = this.filterUser.divisions
      const offices = this.filterUser.offices
      const roles = this.filterUser.roles
      const positions = this.filterUser.positions
      await UserService.list(page, limit, employee_id, name, email, branches, divisions, offices, roles, positions)
          .then((res) => {
            if (res && res.data) {
              this.listUsers = res.data.users.map((e) => {
                e.doughnut = false
                e.checked = false
                return e
              })
              this.pushItem = []
              this.paginate = {
                currentPage: res.data.current_page,
                lastPage: res.data.last_page,
                perPage: res.data.per_page * 1,
                total: res.data.total,
              }
            }
            this.openSearch = false
          })
          .catch((err) => {
            this.$toast.error(err.data.message)
            this.openSearch = false
          })
    },
    toggleCheckAll() {
      this.pushItem = []
      this.hasChecked = false
      this.listUsers.map((e) => {
        if (this.userInfo.id !== e.id) {
          e.checked = this.checkAll
        }
        if (e.checked) {
          this.hasChecked = true
        }
      })
      if (this.checkAll) {
        this.listUsers.map((e) => {
          if (this.userInfo.id !== e.id) {
            this.pushItem = [...this.pushItem, ...[e.id]]
          }
        })
      }
    },
    checkItem(e) {
      if (!e.checked) {
        this.checkAll = false
        let index = findIndex(this.pushItem, el => el === e.id)
        if (index >= 0) {
          this.pushItem.splice(index, 1)
        }
      } else {
        this.hasChecked = true
        this.pushItem = [...this.pushItem, ...[e.id]]
      }
      if (!this.pushItem.length) {
        this.hasChecked = false
      } else if (this.pushItem.length === this.listUsers.filter((e) => this.userInfo.id !== e.id).length) {
        this.checkAll = true
      }
    },
    changePage(page) {
      this.hasChecked = false;
      this.checkAll = false;
      this.listUsers.map((e) => {
        if (e.checked) {
          this.pushItem.splice(this.pushItem.indexOf(e.id));
        }
      });
      this.getUsers(page, this.paginate.perPage)
    },
    toggleSearch(body = false) {
      this.checkAll = false
      this.hasChecked = false
      if (!body || (body && !this.openSearch)) {
        this.openSearch = !this.openSearch
      }
    },
    closeModal(id) {
      if (this.modalOpened) {
        this.$nextTick(() => {
          if (this.$el.querySelector(`#${id}`)) {
            this.$el.querySelector(`#${id}`).classList.remove('is-active')
          }
        })
        setTimeout(() => {
          this.modalOpened = false
          this.displayTooltip = true
        })
      }
    },
    deleteAllUser() {
      this.$popup(
          this.$t('users_management.popup.delete_user.title'),
          this.$t('users_management.popup.delete_user.content'),
          {
            okText: this.$t('users_management.popup.delete_user.ok'),
            cancelText: this.$t('users_management.popup.delete_user.cancel'),
            iconClass: 'info-circle',
            type: 'warning'
          },
          {
            onOk: async (d) => {
              let data = {
                ids: this.pushItem.join(","),
                _method: 'DELETE'
              };
              await UserService.deleteAll(data)
                  .then(() => {
                    this.checkAll = false;
                    this.$toast.success(this.$t('users_management.messages.delete_success'))
                    this.getUsers(this.paginate.currentPage, this.paginate.perPage)
                    this.hasChecked = false
                  })
                  .catch((err) => {
                    console.log(err)
                    this.$toast.error(this.$t('users_management.messages.delete_failed'))
                  })
              d.closeDialog()
            },
            onCancel: (d) => {
              d.closeDialog()
            }
          }
      )
    },
    myEventHandler() {
      if (window.innerWidth <= 1024) {
        this.isTablet = true
      } else {
        this.isTablet = false
      }
      this.windowSize = window.innerWidth
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    if (window.innerWidth <= 1024) {
      this.isTablet = true
    } else {
      this.isTablet = false
    }
    this.getData('offices')
    this.getData('positions')
    this.getData('divisions')
    this.getData('branches')
    this.openSearch = true
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'users')
    this.$store.dispatch(ACTION_SET_PAGE_TITLE, this.$t('users_management.page_title.list'))
    this.paginate.currentPage = 1
    this.paginate.perPage = 10
    this.$nextTick(() => {
      // let params = this.$store.getters['searchParams'].users
      let params = localStorage.getItem('searchParams') ? JSON.parse(localStorage.getItem('searchParams')) : {}
      if (params && Object.keys(params).length) {
        Object.keys(params).map((e) => {
          if (e !== 'page' && e !== 'limit') {
            this.filterUser[e] = params[e]
          }
          if (e === 'page') {
            this.paginate.currentPage = params[e]
          }
          if (e === 'limit') {
            this.paginate.perPage = params[e]
          }
        })
        this.searchUser()
      }
    })
  },
  mounted() {
    let page_content_main = this.$el.querySelector('.page-main-content')
    let search_button = this.$el.querySelector('.search-button')
    let home_page = document.querySelector('.home-page-main')
    home_page.classList.add('bg_none')
    let padding = 120
    if (this.isTablet) {
      padding = 40
    }
    search_button.style.width = (page_content_main.clientWidth - padding + "px")
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        vertical-align: middle;
      }
    }
  }

  tbody {
    tr {
      &.is-checked {
        background-color: #ecd6e5 !important;
      }

      &.is-auth {
        background-color: #CCCCCC !important;
        cursor: not-allowed;
      }

      td {
        &.email {
          /*vertical-align: middle;*/
          overflow: hidden;
          white-space: nowrap;
          max-width: 150px !important;
          text-overflow: ellipsis;
        }

        .is-auth {
          background-color: #CCCCCC !important;
          cursor: not-allowed
        }
      }
    }
  }

  .col-action {
    max-width: 150px !important;
    width: 150px;
  }

}

.modal {
  .modal-card {
    .modal-card-body {
      /*min-height: 500px;*/
      /*border-radius: 25px;*/

      .modal-field-content {
        padding: 0 20px;

        .toggle-password {
          position: absolute;
          top: 10px;
          right: 12px;
          color: gray;
          z-index: 10;

          &:hover {
            cursor: pointer;
          }
        }

        .dropdown-content {
          max-height: 200px;
          overflow-y: scroll;
        }
      }

      .modal-buttons-footer {
        margin-top: 70px;
      }

      .content-modal-change-status {
        justify-content: center;
        padding-top: 60px;
      }
      .content-modal-change-status {
        justify-content: center;
        padding-top: 50px;
      }

      .choose-status {
        display: flex;
        justify-content: flex-end;
        padding-right: 90px;
      }
    }
  }
}

.is-opacity {
  opacity: 1;
  transition: all 0.5s linear;
}

.not-opacity {
  opacity: 0;
  transition: all 0.5s linear;
}
.search {
  .columns{
    margin: 0 !important;
    .column {
      padding: 0 !important;
      padding-top: 12px !important;
    }
    .column:nth-child(1) {
      padding-right: 10px !important;
    }
    .column:nth-child(2) {
      padding-left: 10px !important;
    }
  }
}
.page-main-content {
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 10px;
  &.is-filter {
    max-height: 70px;
    border-radius: 0px 0px 10px 10px !important;
    max-width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, max-width 0.3s ease-in-out, padding-left 0.3s linear, padding-right 0.3s linear;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
    position: relative;

    .search-button {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      position: absolute;
      top: 10px;
      color: $blue_main;
      input {
        background-color: $gray_dark !important;
        border-radius: 20px !important;
        height: 40px;
      }
    }
    .search {
      .columns {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      ~ .columns {
        .column-toggle-search {
          svg {
            transform: rotateZ(0deg);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
      .search-free-word {
        .columns {
          margin: 0px !important;
          .column {
            padding-top: 0px;
            padding-left: 0px;
            &.condition {
              &.column {
                padding-bottom: 0px;
              }
            }

          }
        }
      }
    }

    &.is-active {
      max-height: 1600px;
      max-width: 100%;
      transition: max-height 0.3s ease-in-out, max-width 0.3s ease-in-out, padding-left 0.3s linear, padding-right 0.3s linear;
      padding: 10px 60px;
      .search-button {
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      .search {
        .columns {
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }

        ~ .columns {
          .column-toggle-search {
          }
        }
      }
    }
  }
}


.column-toggle-search {
  color: #808080;

  .line-toggle-search {
    background-color: #b5b5b5;
    width: 200px;
    margin: 6px 0;
  }

  &:hover {
    cursor: pointer;
    color: $blue_main;

    .line-toggle-search {
      background-color: #c585b2 ;
      box-shadow: 0 0 5px #c585b2 ;
    }
  }
}

.blob {
  width: 34px;
  height: 34px;
  top: 1px;
  left: 1px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
  }
}
.button {
  &.btn-change-page-range {
    height: 20px !important;
    width: 80px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  .content-modal-change-status {
    label {
      white-space: normal;
      text-align: center
    }
  }
  button {
    &.is-action.action-success {
      padding: 0 !important;
    }
  }
  .button {
    &.btn-change-page-range {
      padding: 0 0 0 15px !important
    }

    &.btn-save-small {
      padding: 0 !important;
    }

    &.btn-delete-small {
      padding: 0 !important;
    }
  }
  .input-text {
    min-width: 150px !important
  }
  .data-table th {
    &.col-checkbox {
      min-width: 50px !important;
    }
  }
  .modal {
    .modal-content {
      .modal-box {
        .box-title {
          font-size: 18px !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  .search-title {
    padding: 10px 20px !important;
  }

  .page-main-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
    &.is-filter {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}
.search-title {
  padding: 10px 60px;
  border-radius: 10px 10px 0 0;
}

.btn-save {
  width: 140px !important;
  height: 35px !important;
  border-radius: 18px !important;
  background-color: $blue_main !important;
  border: none !important;
  font-size: 16px !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-back {
  border-radius: 18px !important;
  height: 35px !important;
  width: 140px !important;
  border:  none !important;
  font-size: 16px !important;
  background-color: #E4E4E4 !important;
  color: #979797 !important;
}

.icon-search {
  padding: 5px;
  width: 50px !important;
  height: 35px !important;
  top: 2px !important;
  div {
    height: 30px !important;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    background-color: $blue_main;
  }
}

.icon-create {
  margin-right: 10px !important;
  margin-left: -10px !important;
  width: 18px !important;
  div {
    height: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    color: $blue_main;
    background-color: white;
  }
}

.search-free-word {
  min-height: 100px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }

  &:hover {
    //box-shadow: 0 6px 3px -3px #d8d6d6;
    &::-webkit-scrollbar {
      display: block !important;
    }

    .column-action {
      width: calc(100% - 15px);
    }
  }
;

  .modal-field-content:not(:first-child) {
    display: flex;
  }
}

.btn-create {
  border: none;
  border-radius: 30px !important;
  box-shadow: none;
  color: white;
  padding: 4px 10px 4px 4px;
  height: 25px !important;
}
.btn-delete-user {
  border: none;
  border-radius: 30px !important;
  box-shadow: none;
  background-color: #DA354A;
  color: white;
  padding: 4px 10px 4px 4px !important;
  height: 25px;
}
.button-action-detail {
  border: none !important;
  border-radius: 100% !important;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.radio-status-user {
  border: 1px solid $blue_main;
}
.modal-card-foot {
  border-top: none !important;
  padding-bottom: 50px !important;
  padding-top: 40px !important;
}

.input {
  height: 40px;
  color: $blue_button;
}

.input::placeholder {
  font-size: 14px !important;
}

.input:valid {
  font-size: 14px !important;
}

.btn-save-small {
  min-width: 100px;
  height: 25px;
}
.data-table th {
  background-color: #54B6E7 !important;
}

.input:hover {
  border-color: $blue_button !important;
  box-shadow: none !important;
}

.input:focus {
  border-color: $blue_button !important;
  box-shadow: none !important;
}
</style>
